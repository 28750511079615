import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

import { FaTwitter, FaLinkedinIn, FaFacebookF } from 'react-icons/fa';

class BlogAuthor extends React.Component {
  render() {
    const { author } = this.props;
    const imageOnly = _imageOnly(author);

    return <div className="blog-author-component">
      <div className="blog-author position-relative">
        <div className="row px-3 align-items-center justify-content-center">
          <div className="author-image col-6 text-end">
            <GatsbyImage
              image={author.profilePicture.gatsbyImageData}
              className="rounded-circle"
              alt={author.title ?? ''} />
          </div>
          {!imageOnly && <div className="col-6">
            <div className="socials">
              <h5 className="m-0">{author.name}</h5>
              {author.title && <p className="small">{author.title}</p>}
              <ul className="list-inline mb-0 ps-0 pt-2">
              {author.facebookIdentifier && <li className="list-inline-item">
                  <a className="p-0 d-flex justify-content-center align-items-center" href={`https://www.facebook.com/${author.facebookIdentifier}`} target="_new">
                    <FaFacebookF className="social-link-icon w-100" />
                  </a>
                </li>}
                {author.linkedInIdentifier && <li className="list-inline-item">
                  <a className="rounded-circle p-0 d-flex justify-content-center align-items-center" href={`https://www.linkedin.com/company/${author.linkedInIdentifier}`} target="_new">
                    <FaLinkedinIn className="social-link-icon" />
                  </a>
                </li>}
                {author.twitterIdentifier && <li className="list-inline-item">
                  <a className="p-0 d-flex justify-content-center align-items-center" href={`https://www.twitter.com/${author.twitterIdentifier}`} target="_new">
                    <FaTwitter className="social-link-icon w-100" />
                  </a>
                </li>}
              </ul>
            </div>
          </div>}
        </div>
      </div>
    </div>;
  }
}

function _imageOnly(author) {
  return !author.name && !author.title && !author.twitterId && !author.linkedInId;
}

BlogAuthor.propTypes = {
  author: PropTypes.object
};

export default BlogAuthor;
