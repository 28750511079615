import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

const Paginate = (props) => {
    const { firstPageLabel, previousPageLabel, nextPageLabel, lastPageLabel } = props;
    const currentPage = props.currentPage;
    const numberOfPages = props.allPages;

    return <div className="pagination-component mt-4 w-100">
        <ul className="pagination w-100 d-flex">
            <li className={`page-item ${currentPage < 2 ? 'disabled' : ''}`}>
                {currentPage > 1 && <Link className="page-link" to={`/${props.baseUrl}`}>
                    <i className="fa fa-angle-double-left me-2" aria-hidden="true"></i>
                    <span className="d-none d-md-inline">{firstPageLabel}</span>
                </Link>}
                {currentPage < 2 && <button className="page-link disabled" disabled={true}>
                    <i className="fa fa-angle-double-left me-2" aria-hidden="true"></i>
                    <span className="d-none d-md-inline">{firstPageLabel}</span>
                </button>}
            </li>
            <li className={`page-item ${currentPage < 2 ? 'disabled' : ''} me-auto`}>
                {currentPage > 1 && <Link className="page-link" to={props.prevPage}>
                    <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                    <span className="d-none d-md-inline">{previousPageLabel}</span>
                </Link>}
                {currentPage < 2 && <button className="page-link disabled" disabled={true} >
                    <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                    <span className="d-none d-md-inline">{previousPageLabel}</span>
                </button>}
            </li>
            <li className={`page-item ${currentPage >= numberOfPages ? 'disabled' : ''}`}>
                {currentPage < numberOfPages && <Link className="page-link" to={props.nextPage}>
                    <span className="d-none d-md-inline">{nextPageLabel}</span>
                    <i className="fa fa-angle-right ms-2" aria-hidden="true"></i>
                </Link>}
                {currentPage >= numberOfPages && <button className="page-link disabled" disabled={true} >
                    <span className="d-none d-md-inline">{nextPageLabel}</span>
                    <i className="fa fa-angle-right ms-2" aria-hidden="true"></i>
                </button>}
            </li>
            <li className={`page-item ${currentPage >= numberOfPages ? 'disabled' : ''}`}>
                {currentPage < numberOfPages && <Link className="page-link" to={`/${props.baseUrl}/${numberOfPages}`}>
                    <span className="d-none d-md-inline">{lastPageLabel}</span>
                    <i className="fa fa-angle-double-right ms-2" aria-hidden="true"></i>
                </Link>}
                {currentPage >= numberOfPages && <button className="page-link disabled" disabled={true} >
                    <span className="d-none d-md-inline">{lastPageLabel}</span>
                    <i className="fa fa-angle-double-right ms-2" aria-hidden="true"></i>
                </button>}
            </li>

        </ul>
    </div>;
};

Paginate.propTypes = {
    currentPage: PropTypes.number,
    allPages: PropTypes.number,
    prevPage: PropTypes.string,
    nextPage: PropTypes.string,
    baseUrl: PropTypes.string
};

export default Paginate;