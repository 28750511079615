import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import useOutsideClick from '../hooks/useOutsideClick';

const Dropdown = ({
    options = [],
    className,
    value,
    placeholder,
    onChange
}) => {
    const [_value, setValue] = useState(value);
    const [show, setShow] = useState(false);
    const wrapperRef = useRef(null);
    const getDisplayName = () => options.find((option) => option.value === _value)?.name || placeholder;
    const toggleShow = () => setShow(!show);
    const valueHandler = (value) => {
        if(value !== _value) {
            onChange(value);
            setValue(value);
        }
        toggleShow();
    };
    useOutsideClick(wrapperRef, () => setShow(false));

    return <div ref={wrapperRef} className={classnames('dropdown dropdown-component', className, { show })}>
        <button
            onClick={toggleShow}
            className="btn btn-outline-primary dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            {getDisplayName()}
        </button>
        <div className={classnames('dropdown-menu', { show })}>
            {options.map(({ value, name }) => <a
            key={value}
            className="dropdown-item"
            onClick={() => valueHandler(value)}
            href="#"
            >
                {name}
            </a>)}
        </div>
    </div>;
};

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.objectOf),
    className: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
};

Dropdown.defaultProps = {
    options: [],
    className: '',
    value: '',
    placeholder: 'Select',
    onChange: () => {}
};
export default Dropdown;
