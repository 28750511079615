import PropTypes from 'prop-types';
import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

export const CaseStudyCard = ({ className = '', description, id, size = 'medium', title, cover, logo, actionLabel }) => {
    const sizeToBootstrapClass = () => {
        switch (size) {
            case 'large':
                return 'col-md-12';
            case 'small':
                return 'col-md-4'
            default:
                return 'col-md-6'
        }
    }
    const showDescription = size !== 'small';
    const showCover = size === 'large' && cover;
    return <div className={`col-12 ${sizeToBootstrapClass()} case-study ${className}`} >
        <div className="card shadow rounded bg-white p-3 p-lg-5 h-100 d-flex flex-column justify-content-between">
            <div className="d-flex mb-3">
                {logo && <div className='d-flex align-items-center'>
                    {
                        logo?.gatsbyImageData ?
                            <GatsbyImage
                                image={logo?.gatsbyImageData}
                                alt={title}
                                className='gatsby-image-logo'
                                style={{ height: '45px', width: '45px' }}
                            />
                            :
                            <div className="me-2 logo">
                                <img src={logo} alt="logo" />
                            </div>
                    }
                </div>}
                <h3 className="mb-0">{title}</h3>
            </div>
            {showCover && <img src={cover} alt="case study cover" className="img-fluid" />}
            {showDescription && <p>{description}</p>}
            <div className="text-end">
                <a className="btn btn-link" href={`/case-studies/${id}`}>
                    {actionLabel ?? `Read More`}
                </a>
            </div>
        </div>
    </div>;
};

CaseStudyCard.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    title: PropTypes.string.isRequired,
    cover: PropTypes.string,
    logo: PropTypes.string
};