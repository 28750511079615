import { IMAGES_PROTOCOL } from '../resources/constants';

class ImageUtils {
    /**
     * @static
     * @param {string} [url='']
     * @memberof ImageUtils
     * @returns Image URL with protocol
     */
    static buildImgUrl = (url = '') => {
        if (!url) return '';
        if (url.includes('https:')) return url;
        return `${IMAGES_PROTOCOL}${url}`;
    };

    static staticToGatsbyImage = (url = '') => {
        return {
            gatsbyImageData: {
                height: 1,
                width: 1,
                layout: 'fullWidth',
                images: {
                    sources: [
                        {
                            size: '100vw',
                            srcSet: url
                        }
                    ]
                }
            }
        }
    }
}

export default ImageUtils;
