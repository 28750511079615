import React from 'react';
import Link from 'gatsby-link';
import Button from '../components/button';
import devops from '../styles/img/devops.png';
import fullStack from '../styles/img/full-stack.png';
import qualityAssurance from '../styles/img/quality-assurance.png';
import recruiter from '../styles/img/recruiter.png';
import administrationImg from '../styles/img/administration.png';
import businessDevelopmentImg from '../styles/img/business-development.png';
import dataScientistImg from '../styles/img/data-scientist.png';
import designerImg from '../styles/img/designer.png';
import javaDeveloperImg from '../styles/img/java-developer.png';
import hrOfficeImg from '../styles/img/hr-officer.png';
import marketerImg from '../styles/img/marketer.png';
import phpDeveloperImg from '../styles/img/php-developer.png';
import seoSpecialistImg from '../styles/img/seo-specialist.png';
import nodeDeveloperImg from '../styles/img/node-developer.png';
import uiUxImg from '../styles/img/ui-ux.png';

const CAREER_IMAGES = {
    admin: administrationImg,
    business_development: businessDevelopmentImg,
    dotnet_developer: javaDeveloperImg,
    data_scientist: dataScientistImg,
    designer: designerImg,
    devops_engineer: devops,
    full_stack_developer: fullStack,
    java_developer: javaDeveloperImg,
    hr_officer: hrOfficeImg,
    marketer: marketerImg,
    mobile_developer: marketerImg,
    node_developer: nodeDeveloperImg,
    php_developer: phpDeveloperImg,
    qa_tester: qualityAssurance,
    recruiter: recruiter,
    seo_specialist: seoSpecialistImg,
    ui_developer: uiUxImg
}

const OpenPositionFeed = ({
    positions
}) => {

    const getImage = (position) => {
        // if(position?.images) {
        //     return position.images[0].fullS3Path
        // }
        return CAREER_IMAGES[position.category]
    }

    return <>
        {positions.map((position, index) => <React.Fragment key={index}>
            {index !== 0 && <hr className="hr-line" />}
            <div className="row mb-3">
                <div className="col-md-12 col-lg-5">
                    <img
                        src={getImage(position)}
                        alt={position.category}
                        className="rounded img-fluid"
                    />
                </div>
                <div className="col-md-12 col-lg-7 d-flex flex-column justify-content-around">
                    <Link className="text-decoration-none" to={`/careers/${position.id}`}>
                        <h3 className="mt-2">{position.title}</h3>
                    </Link>
                    <div className="d-flex meta-data justify-content-between pb-1">
                        {position.type.length && <div className="d-flex col-3 align-items-center">
                            <i className="ti-layout-grid2"></i>
                            <span className="ms-2">
                                {position.type}
                            </span>
                        </div>}
                        {position?.countries?.length && <div className="d-flex align-items-center">
                            <i className="ti-location-pin"></i>
                            <span className="ms-2">
                                {position.countries}
                            </span>
                        </div>}
                    </div>
                    <p>{position.summary}</p>
                    <Button
                        href={`/careers/${position.id}`}
                        className="btn-primary"
                        size="small"
                        type="link"
                    >
                        Read more
                    </Button>
                </div>
            </div>
        </React.Fragment>)}
    </>
};

export default OpenPositionFeed;
