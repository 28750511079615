import React, { useState } from 'react';
import classnames from 'classnames';
import Layout from '../components/layout';
import HelmetEx from '../components/helmet-ex';
import Banner from '../components/banner';
import Footer from '../components/footer';
import Paginate from '../components/paginate';
import ImageContentRow from '../components/image-content-row';
import OfficeImage from '../styles/img/pages/home/office.jpeg';
import Accordion from '../components/accordion';
import { CaseStudyCard } from '../components/case-study-card';
import DeadlineCounter from '../components/deadline-counter';
import LogoSlider from '../components/logo-slider';
import Grid from '../components/grid';
import Tabs from '../components/tabs';
import Testmonial from '../components/testmonial';
import Share from '../components/share';
import Card from '../components/card';
import NestedSection from '../components/nested-section';
import Section from '../components/section';
import LinkButton from '../components/link-button';
import CareerPosition from '../components/career-position';
import ContactUs from '../components/contact-us';
import Hero from '../components/hero';
import ImageUtils from '../utils/image-utils';
import BlogAuthor from '../components/blog-author';
import Navigation from '../components/navigation';
import Dropdown from '../components/dropdown';
import OpenPositionFeed from '../components/open-positions-feed';
import TypedHeader from '../components/typed-header';
import NewsLetter from '../components/news-letter';

const dummyText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et mollis purus, sit amet feugiat velit. Praesent quis ultricies nulla. Mauris eget dapibus urna. Maecenas ac magna sed enim elementum ullamcorper.';

const BrandColors = () => {

    const colors = [
        ['ffffffff', '193eb0ff', '1098f7ff', 'f6ae2dff', '000000ff'],
        ['f4ededff', '193eb0ff', '36827fff', 'f6ae2dff', 'b38cb4ff'],
        ['ffffffff', '193eb0ff', 'ffe8d1ff', '68c3d4ff', '826251ff'],
        ['eef4edff', '193eb0ff', '8da9c4ff', 'ef767aff', '0c090dff'],
        ['fefffeff', '193eb0ff', '9bc53dff', '000000ff', 'ff9f1cff'],
        ['193eb0', '222222', 'ffffff', '639fab', 'bbcde5'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '05B2DC'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '44CF6C'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '868784'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '9792E3'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '0EB1D2'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '59A5D8'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '36827F'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '0B5351'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '45B69C'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '157A6E'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '119DA4'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '628B48'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '1A936F'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '0B7A75'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '1C7C54'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '9A94BC'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', 'A491D3'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '678D58'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '7AC74F'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '17C3B2'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '0892A5'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', 'AA4465'],
        ['fefffeff', '193eb0ff', 'FF9F1C', '000000ff', '449DD1'],
        ['F2F4F3', '193eb0ff', 'FF9F1C', '000000ff', '449DD1'],
        ['FFFFFF', '46494C', 'E9724C', '57A773', '1789FC']

    ];

    return <>
        {/* Generally, you can have up two 3 “primary” brand colors and “4-5 secondary” colors. */}
        <div>
            <h2 className="mb-3">Primary Brand Colors</h2>
            <p>
                Primary brand colors are the main, consistent colors used in all graphics, publications, signage, ect.
                Primary brand color rarely change because they are central to the brand’s visual identity.
            </p>
            <div className="row mx-0">
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-primary-1" colorCode="193eb0" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-primary-2" colorCode="0e2363" />
                </div>
            </div>
        </div>
        <div className="mt-4">
            <h2 className="mb-3">Secondary (Accent) Brand Colors</h2>
            <p>
                Secondary brand colors act as a complimentary color pallet to the primary brand colors. These colors are
                updated more frequently and often change to reflect strategy trends and marketing goals.
            </p>
            <div className="row mx-0">
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-accent-1" colorCode="204fe2" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-accent-2" colorCode="7cd3da" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-accent-3" colorCode="54bb69" />
                </div>
            </div>
            <div className="row mt-4 mx-0">
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-accent-4" colorCode="fc7a1e" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-accent-5" colorCode="3f19b0" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-accent-6" colorCode="b0193e" />
                </div>
            </div>
        </div>
        <div className="mt-4">
            <h2 className="mb-3">Neutral Brand Colors</h2>
            <p>
                Neutral color will most likely be a background color, something chosen to avoid attention. Typically
                these are different hues of gray, but beige, whites and off-whites work, too. Black is also an option, but
                be careful; it tends to dominate any color scheme it's a part of.
            </p>
            <div className="row mx-0">
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-neutral-1" colorCode="FFFFFF" textColor="text-black" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-neutral-2" colorCode="e6e6e6" textColor="text-black" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-neutral-3" colorCode="404040" />
                </div>
            </div>
            <div className="row mt-4 mx-0">
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-neutral-4" colorCode="808cad" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-neutral-5" colorCode="51586e" />
                </div>
                <div className="col-12 col-sm-4">
                    <ColorCard className="bg-brand-neutral-6" colorCode="000000" />
                </div>
            </div>
        </div>
        <div className="mt-4">
            <h2 className="mb-3">Color Palettes</h2>
            {colors.map((palette, index) =>
                <div className="row mb-2">
                    <div className="col-2">
                        <h3># {index + 1}:</h3>
                    </div>
                    {palette.map(color =>
                        <div className="col-2" style={{ backgroundColor: `#${color}` }}>
                            #{color} !important
                        </div>
                    )}
                </div>
            )}
        </div>
    </>;
};

const BrandGuideline = () => {
    const [currentTab, setCurrentTab] = useState('Colors');
    const tabs = ["Colors", "Typography", "Web Elements", "CMS Elements"];

    const onTabClick = (event) => {
        event.preventDefault();
        setCurrentTab(event.target.name);
    };

    return <Layout>
        <div className="brand-guideline-page">
            <HelmetEx noIndex>
                <div metadata="title">Brand Guideline</div>
                <div metadata="keywords">Brand, Guideline, colors, font, font sizes</div>
                <div metadata="path">/brand-guideline</div>
                <div metadata="canonicalurl">/brand-guideline</div>
                <div metadata="description">
                    Brand colors, font family and font sizes
                </div>
            </HelmetEx>
            <Banner name="Brand Guideline" />
            <div className="py-4 body">

                <ul className="container nav nav-tabs mb-4">
                    {tabs.map((tab, index) =>
                        <li className="nav-item" key={index}>
                            <a className={`nav-link ${currentTab === tab ? 'active' : ''}`} aria-current="page" href="#" name={tab} onClick={onTabClick}>{tab}</a>
                        </li>
                    )}
                </ul>
                <div className="container">
                    {currentTab === 'Colors' && <BrandColors />}
                    {currentTab === 'Typography' && <Typography />}
                    {currentTab === 'Web Elements' && <WebElements />}
                </div>
                {currentTab === 'CMS Elements' && <CmsElements />}
            </div>
        </div>
        <Footer />
    </Layout>;
};

const CmsElements = () => {
    const [active, setActive] = useState('accordion');
    const ComponentWrapper = ({
        container = true,
        children,
        title
    }) => {
        return <div className={classnames({
            container,
        })}>
            <div className={classnames({
                container: !container,
            })}>
                <h2>{title}</h2>
            </div>
            {children}
        </div>;
    };
    const components = {
        accordion: <ComponentWrapper title="Accordion">
            <Accordion
                accordionItems={[
                    {
                        content: { content: 'I am the first item' },
                        title: 'First item'
                    },
                    {
                        content: { content: 'I am the second item' },
                        title: 'Second item'
                    }
                ]}
            />
        </ComponentWrapper>,
        casestudycard: <ComponentWrapper title="Case Study Card">
            <div className="row g-5">
                <CaseStudyCard
                    id="case-study-id"
                    description="This is small card case study description"
                    size="small"
                    title="Small Case Study"
                    logo="https://via.placeholder.com/150.png?text=logo"
                    cover="https://via.placeholder.com/150"
                />
                <CaseStudyCard
                    id="case-study-id"
                    description="This is small card case study description"
                    size="small"
                    title="Small Case Study"
                    logo="https://via.placeholder.com/150.png?text=logo"
                    cover="https://via.placeholder.com/150"
                />
                <CaseStudyCard
                    id="case-study-id"
                    description="This is small card case study description"
                    size="small"
                    title="Small Case Study"
                    logo="https://via.placeholder.com/150.png?text=logo"
                    cover="https://via.placeholder.com/150"
                />
                <CaseStudyCard
                    id="case-study-id-1"
                    description="This is medium card case study description"
                    title="Medium Case Study"
                    logo="https://via.placeholder.com/150.png?text=logo"
                    cover="https://via.placeholder.com/150"
                />
                <CaseStudyCard
                    id="case-study-id-1"
                    description="This is medium card case study description"
                    title="Medium Case Study"
                    logo="https://via.placeholder.com/150.png?text=logo"
                    cover="https://via.placeholder.com/150"
                />
                <CaseStudyCard
                    id="case-study-id-2"
                    description="This is large card case study description"
                    size="large"
                    title="Large Case Study"
                    logo="https://via.placeholder.com/150.png?text=logo"
                    cover="https://via.placeholder.com/1500x200.png?text=Case+Study+Cover+Image"
                />
            </div>
        </ComponentWrapper>,
        imagecontentrow: <ComponentWrapper title="Image Content Row">
            <ImageContentRow
                title="Sample #1"
                description="I am a simple content row, plain text, image to left"
                src={{
                    fixed: {
                        src: OfficeImage
                    }
                }}
            />
            <ImageContentRow
                flip={true}
                title="Sample #2"
                description="I am also simple content row, plain text, image to right. I also have a 'learn more' action"
                learnMoreActionPath="/brand-guideline/"
                src={{
                    fixed: {
                        src: OfficeImage
                    }
                }}
            />
            <ImageContentRow
                title="Sample #3 - Title and description"
                description="I am also simple full-width content row, plain text, no image"
                hideImage={true}
            />
        </ComponentWrapper>,
        deadlinecounter: <ComponentWrapper title="Deadline Counter">
            <DeadlineCounter endDateTime={new Date('12-12-2022')} />
        </ComponentWrapper>,
        brandslider: <ComponentWrapper title="BrandSlider">
            <div className="d-none d-lg-block">
                <LogoSlider slidesToShow={5} slidesToScroll={2} />
            </div>
            <div className="d-none d-lg-none d-md-block">
                <LogoSlider slidesToShow={2} slidesToScroll={2} />
            </div>
            <div className="d-block d-md-none">
                <LogoSlider slidesToShow={2} slidesToScroll={1} />
            </div>
        </ComponentWrapper>,
        grid: <ComponentWrapper title="Grid">
            <Grid columns={[dummyText, dummyText, dummyText]} />
        </ComponentWrapper>,
        tabs: <ComponentWrapper title="Tabs">
            <Tabs
                tabs={[
                    {
                        path: '#',
                        text: 'First tab'
                    },
                    {
                        path: '#',
                        text: 'Second tab'
                    },
                    {
                        path: '#',
                        text: 'Third tab'
                    }
                ]}
                activeTab="Second tab"
            />
        </ComponentWrapper>,
        testmonial: <ComponentWrapper title="Testmonial">
            <div className="col-12 col-md-6">
                <Testmonial
                    name="John Doe"
                    title="Title and Company"
                    body={dummyText}
                    quote={dummyText}
                    profilePicture={{
                        gatsbyImageData: {
                            height: 1,
                            width: 1,
                            layout: 'fullWidth',
                            images: {
                                sources: [
                                    {
                                        size: '100vw',
                                        srcSet: 'https://www.w3schools.com/howto/img_avatar.png'
                                    }
                                ]
                            }
                        }
                    }}
                />
            </div>
        </ComponentWrapper>,
        sharebuttons: <ComponentWrapper title="Social Media Share buttons">
            <Share
                url="#"
                tags={['tag1', 'tag2', 'tag3']}
                buttons={['facebook', 'twitter', 'linkedin']}
                horizontalAlignment='left'
            />
        </ComponentWrapper>,
        cards: <ComponentWrapper title="Cards">
            <div className="row g-5">
                <div className="col-12 col-md-4">
                    <Card title="Small Size Image in the Middle Card"
                        body={dummyText}
                        image={{
                            file: {
                                url: 'https://via.placeholder.com/90x90.png?text=card'
                            }
                        }}
                        imagePosition="middle"
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Card
                        title="Full Width Image Top Card with Shadow"
                        body={dummyText}
                        image={{
                            gatsbyImageData: {
                                height: 1,
                                width: 1,
                                layout: 'fullWidth',
                                images: {
                                    sources: [
                                        {
                                            size: '100vw',
                                            srcSet: 'https://via.placeholder.com/200x200.png?text=card'
                                        }
                                    ]
                                }
                            }
                        }}
                        imagePosition="top"
                        imageSize="large"
                        cardStyle='box-shadow'
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Card
                        title="Medium Size Image Bottom Card no borders"
                        body={dummyText}
                        image={{
                            file: {
                                url: 'https://via.placeholder.com/90x90.png?text=card'
                            }
                        }}
                        imagePosition="bottom"
                        imageSize="medium"
                        cardStyle='none'
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Card
                        title="Flat card style"
                        body={dummyText}
                        image={{
                            file: {
                                url: 'https://via.placeholder.com/90x90.png?text=card'
                            }
                        }}
                        imagePosition="bottom"
                        imageSize="medium"
                        cardStyle='flat'
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Card
                        title="Standard card style"
                        body={dummyText}
                        image={{
                            file: {
                                url: 'https://via.placeholder.com/90x90.png?text=card'
                            }
                        }}
                        imagePosition="bottom"
                        imageSize="medium"
                        cardStyle='standard'
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Card
                        title="Dynamic card style"
                        body={dummyText}
                        image={{
                            file: {
                                url: 'https://via.placeholder.com/90x90.png?text=card'
                            }
                        }}
                        imagePosition="bottom"
                        imageSize="medium"
                        cardStyle='dynamic'
                    />
                </div>
                <div className="col-12 col-md-4">
                    <Card
                        title="Static card style"
                        body={dummyText}
                        image={{
                            file: {
                                url: 'https://via.placeholder.com/90x90.png?text=card'
                            }
                        }}
                        imagePosition="bottom"
                        imageSize="medium"
                        cardStyle='static'
                    />
                </div>
            </div>
        </ComponentWrapper>,
        linkbutton: <ComponentWrapper title="Link Button">
            <LinkButton color="primary" startDate={new Date()} endDate={new Date('12-12-2022')} url="#">
                Link button
            </LinkButton>
        </ComponentWrapper>,
        nestedsection: <ComponentWrapper title="Nested Section" container={false}>
            <NestedSection
                link="#"
                linkText={"Read more"}
                column1={dummyText}
                column2={dummyText}
                title="Nested section title"
            />
        </ComponentWrapper>,
        sections: <ComponentWrapper title="Sections" container={false}>
            <Section
                backgroundColor='light'
                content={dummyText}
                title="Section with light background"
                yPaddings="large"
            />
            <Section
                content={dummyText}
                title="Section with white background and call to action"
                yPaddings="large"
                callToAction='#'
            />
        </ComponentWrapper>,
        openpostions: <ComponentWrapper container={false} title="Open positions">
            <CareerPosition careers={[
                {
                    title: 'Position title 1',
                    summary: dummyText,
                    id: '#1'
                },
                {
                    title: 'Position title 2',
                    summary: dummyText,
                    id: '#2'
                },
                {
                    title: 'Position title 3',
                    summary: dummyText,
                    id: '#3'
                },
                {
                    title: 'Position title 3',
                    summary: dummyText,
                    id: '#3'
                }
            ]} />
        </ComponentWrapper>,
        openpostionsfeed: <ComponentWrapper container={false} title="Open positions feed">
            <div className="open-positions-list">
                <div className="container pt-4">
                    <OpenPositionFeed
                        positions={[
                            {
                                id: 1,
                                category: 'devops_engineer',
                                title: 'Position Title',
                                type: 'Position Type',
                                countries: ['Country'],
                                summary: dummyText
                            },
                            {
                                id: 1,
                                category: 'devops_engineer',
                                title: 'Position Title',
                                type: 'Position Type',
                                countries: ['Country'],
                                summary: dummyText
                            }
                        ]} />
                </div>
            </div>
        </ComponentWrapper>,
        contactus: <ComponentWrapper title="Contact us" container={false}>
            <div className="container">
                <ContactUs type="button" />
            </div>
            <ContactUs type="form" />
        </ComponentWrapper>,
        herosection: <ComponentWrapper title="Hero section">
            <Hero
                heading1="Heading title"
                heading2="has primary color"
                image={ImageUtils.staticToGatsbyImage('https://via.placeholder.com/400?text=hero+image')}
                description={dummyText}
            />
        </ComponentWrapper>,
        blogauthor: <ComponentWrapper title="Blog Author">
            <div className="col-12 col-md-4">
                <BlogAuthor
                    author={{
                        profilePicture: ImageUtils.staticToGatsbyImage('https://via.placeholder.com/400?text=profile'),
                        title: 'Author title',
                        facebookIdentifier: '#',
                        linkedInIdentifier: '#',
                        twitterIdentifier: '#'
                    }}
                />
            </div>
        </ComponentWrapper>,
        navigation: <ComponentWrapper title="Navigation">
            <Navigation />
        </ComponentWrapper>,
        dropdown: <ComponentWrapper title="Dropdown">
            <Dropdown
                options={[
                    {
                        name: 'Item 1',
                        value: 1
                    },
                    {
                        name: 'Item 1',
                        value: 1
                    },
                    {
                        name: 'Item 1',
                        value: 1
                    }
                ]}
            />
        </ComponentWrapper>,
        typedheader: <ComponentWrapper title="Typed header">
            <TypedHeader
                links={{
                    facebookUrl: '/brand-guideline',
                    twitterUrl: '/brand-guideline',
                    linkedInUrl: '/brand-guideline',
                }
                }
                slogans={['First Slogan', dummyText]}
            />
        </ComponentWrapper>,
        newsletter: <ComponentWrapper title="Newsletter">
            <NewsLetter caption='Newsletter Caption' title='Some Newsletter Title' />
        </ComponentWrapper>
    };
    return <>
        <div>
            <div className="container">
                <p>Content Management System (CMS) reusable elements:</p>
                <Dropdown
                    options={[
                        {
                            name: 'All',
                            value: 'all'
                        },
                        {
                            name: 'Accordion',
                            value: 'accordion'
                        },
                        {
                            name: 'Case Study Card',
                            value: 'casestudycard'
                        },
                        {
                            name: 'Image Content Row',
                            value: 'imagecontentrow'
                        },
                        {
                            name: 'Deadline Counter',
                            value: 'deadlinecounter'
                        },
                        {
                            name: 'BrandSlider',
                            value: 'brandslider'
                        },
                        {
                            name: 'Grid',
                            value: 'grid'
                        },
                        {
                            name: 'Tabs',
                            value: 'tabas'
                        },
                        {
                            name: 'Testmonial',
                            value: 'testmonial'
                        },
                        {
                            name: 'Social Media Share buttons',
                            value: 'sharebuttons'
                        },
                        {
                            name: 'Cards',
                            value: 'cards'
                        },
                        {
                            name: 'Link Button',
                            value: 'linkbutton'
                        },
                        {
                            name: 'Nested Section',
                            value: 'nestedsection'
                        }, {
                            name: 'Sections',
                            value: 'sections'
                        }, {
                            name: 'Open positions',
                            value: 'openpostions'
                        }, {
                            name: 'Open positions feed',
                            value: 'openpostionsfeed'
                        }, {
                            name: 'Contact us',
                            value: 'contactus'
                        }, {
                            name: 'Hero section',
                            value: 'herosection'
                        }, {
                            name: 'Blog Author',
                            value: 'blogauthor'
                        }, {
                            name: 'Navigation',
                            value: 'navigation'
                        }, {
                            name: 'Dropdown',
                            value: 'dropdown'
                        }, {
                            name: 'Typed header',
                            value: 'typedheader'
                        }, {
                            name: 'Newsletter',
                            value: 'newsletter'
                        }
                    ].sort((a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    })}
                    value={active}
                    onChange={(value) => setActive(value)}
                    className="mb-3"
                />
            </div>
            {active === 'all' ? Object.keys(components).map((key) => <div>
                <div className="container">
                    <hr className="my-4" />
                </div>
                {components[key]}
            </div>) :
                components[active]}
        </div>
    </>;
};

const ColorCard = ({ className, colorCode, colorCmyk, textColor = "text-white" }) => {
    const rgb = hexToRgb(colorCode);
    return <div className={`${className} p-4 m-1 shadow rounded`}>
        <ul className={`${textColor} text-uppercase`}>
            <li>Web: #{colorCode}</li>
            {rgb && <li>RGB: {rgb.r}, {rgb.g}, {rgb.b}</li>}
            {colorCmyk && <li>CMYK: {colorCmyk}</li>}
        </ul>
    </div>;
};

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, red, green, blue) {
        return red + red + green + green + blue + blue;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

const Typography = () => <>
    <section className="mt-4">
        <h2>Headings</h2>
        <div className="row">
            <div className="col-3">
                <h1>Heading 1</h1>
            </div>
            <div className="col-3">
                <h2>Heading 2</h2>
            </div>
            <div className="col-3">
                <h3>Heading 3</h3>
            </div>
            <div className="col-3">
                <h4>Heading 4</h4>
            </div>
        </div>
        <h2>Text</h2>
        <p>
            I am a paragraph of text. <em>Emphasized I look like this.</em> <strong>This is strong.</strong>
        </p>
        <p>
            <blockquote>I am a quote.</blockquote>
        </p>
        <h2>Bullets</h2>
        <div className="row">
            <div className="col-3">
                <ul>
                    <li>First</li>
                    <li>Second</li>
                </ul>
            </div>
            <div className="col-3">
                <ol>
                    <li>First</li>
                    <li>Second</li>
                </ol>
            </div>
        </div>
        <h2>Font Families</h2>
        <div className="row" style={{ fontSize: 20 }}>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistblack' }}>
                    Geomanist Black
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistbold' }}>
                    Geomanist Bold
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistbook' }}>
                    Geomanist Book
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistextralight' }}>
                    Geomanist Extra Light
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistlight' }}>
                    Geomanist Light
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistmedium' }}>
                    Geomanist Medium
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistregular' }}>
                    Geomanist Regular
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistthin' }}>
                    Geomanist Thin
                </p>
            </div>
            <div className="col-3">
                <p style={{ fontFamily: 'geomanistultra' }}>
                    Geomanist Ultra
                </p>
            </div>
        </div>
    </section>
</>;

const WebElements = () => {
    const pagination = {
        currentPage: 0,
        allPages: 2,
    };
    return <>
        <section className="mt-4">
            <h2>Buttons</h2>
            <div className="row mb-4">
                <div className="col-2">
                    <button className="btn btn-link me-2">Link</button>
                </div>
                <div className="col-2">

                </div>
            </div>
            <h3>Special Buttons</h3>
            <div className="">
                <button className="btn btn-brand-cta-1 me-2">Call to Action</button>
                <button className="btn btn-brand-cta-2 me-2">Call to Action</button>
                <button className="btn btn-brand-cta-3 me-2">Call to Action</button>
            </div>
            <h3 className="mt-4">Special Links</h3>
            <div className="">
                <a className="lnk-brand-cta-1">Call to Action</a>
            </div>
            <h2 className="mt-4">Pagination</h2>
            <Paginate {...pagination} />
            <h3 className="mt-4">Special Text</h3>
            <div className="">
                <div className="row mx-0">
                    <div className="col-12 col-sm-4">
                        <div className="card shadow">
                            <div className="card-body p-4">
                                <h5 className="card-title">Special Text 1</h5>
                                <p className="card-text">
                                    <p className="text-brand-style-1">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                                        ex ea commodo consequat.
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>;
};

export default BrandGuideline;