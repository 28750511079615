import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import BestBuy from '../styles/img/polymath/best-buy.png';
import Highmark from '../styles/img/polymath/highmark.png';
import Jpmorgan from '../styles/img/polymath/jpmorgan.png';
import Pfizer from '../styles/img/polymath/pfizer.png';
import SonyMusic from '../styles/img/polymath/sony-music.png';
import Weightwatchers from '../styles/img/polymath/weightwatchers.png';

class SlickSlider extends Component {
    render() {
        const settings = {
            buttons: false,
            infinite: true,
            speed: 500,
            slidesToShow: this.props.slidesToShow,
            slidesToScroll: this.props.slidesToScroll,
            autoplay: true,
            autoplaySpeed: 3000
        };
        return <div className="slider-tow-component">
            <Slider {...settings}>
                <div>
                    <img src={BestBuy} alt="best buy" />
                </div>
                <div >
                    <img src={Pfizer} alt="pfizer" />
                </div>
                <div >
                    <img src={Jpmorgan} alt="jpmorgan" />
                </div>
                <div >
                    <img src={Highmark} alt="highmark" />
                </div>
                <div >
                    <img src={SonyMusic} alt="sony music" />
                </div>
                <div >
                    <img src={Weightwatchers} alt="weight watchers" />
                </div>
            </Slider>
        </div>;
    }
}

SlickSlider.propTypes = {
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number
};

export default SlickSlider;